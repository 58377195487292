<template>
  <main class="main" data-view="rates">
    <section class="section" id="s1">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-lg-8">
            <div class="rates" v-if="cryptoHistoryApi">
              <div class="rates-graph" :class="{'active': $store.state.isFetching}">
                <div class="rates-graph__info">
                  <div class="rates-graph__row">
                    <div class="rates-graph__head">
                      <template v-if="cryptoActualSelected">
                        <div class="rates-graph__icon">
                          <img :src="`/img/icons/crypto/${selCrypto}.svg`" :alt="selCrypto" />
                        </div>
                        <div class="rates-graph__name">
                          <p class="text ts-14 ts-md-24 tw-400">{{ cryptoActualSelected.name }}</p>
                          <p class="text tc-gray ts-10 ts-md-16 tw-400">{{ selCrypto.toUpperCase() }}</p>
                        </div>
                      </template>
                    </div>
                    <div class="rates-graph__btns">
                      <div class="rates-graph__btns-item" v-for="(item, i) in txtButtons" :key="`btn-item-${i}`" v-html="item" :class="{'active': $store.state.selRange === i}" @click.prevent="changeGraphRanges(i)"/>
                    </div>
                  </div>
                  <div class="rates-graph__row mb-4">
                    <template v-if="cryptoActualSelected && cryptoActualChangeSelected">
                      <div class="rates-graph__price">
                        <p class="text ts-18 ts-md-38 tw-500">CZK {{ cryptoActualSelected.price }}</p>
                        <p class="text ts-10 ts-md-20 tw-400" :class="[{'tc-up': cryptoActualChangeSelected.change.grow}, {'tc-down': !cryptoActualChangeSelected.change.grow}]">CZK {{ cryptoActualChangeSelected.change.amount }} ({{ cryptoActualChangeSelected.change.percent }}%)</p>
                      </div>
                    </template>
                  </div>
                  <div class="rates-graph__row d-block">
                    <rate-graph />
                  </div>
                </div>
              </div>
              <!-- <div class="rates-info">
                <div class="row">
                  <div class="col-6">
                    <div class="rates-info__text">
                      <p class="text ts-14 tw-500 ta-center mb-3">Tržní kapitalizace</p>
                      <p class="text ts-14 tw-400 ta-center" v-if="cryptoAssetData && cryptoBlockHeight">${{ cryptoBlockHeight[selCrypto] ? cryptoAssetData[selCrypto].price * cryptoBlockHeight[selCrypto] : '--' }} <span class="text tc-waikawa ts-12">USD</span></p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="rates-info__text">
                      <p class="text ts-14 tw-500 ta-center mb-3">Objem trhu</p>
                      <p class="text ts-14 tw-400 ta-center" v-if="cryptoAssetData">{{ cryptoAssetData[selCrypto].volume }} <span class="text tc-waikawa ts-12">{{ selCrypto.toUpperCase() }}</span></p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="rates-list">
              <div class="rates-list__row">
                <p class="text tc-waikawa ts-20 tw-500 ta-center">Kryptoměny</p>
              </div>
              <div class="rates-list__row" v-if="cryptoActualApi">
                <div class="rates-list__slider">
                  <div class="rates-list__slider-wrapper" :style="`width: 300%; ${sliderTranslate}`">
                    <div class="rates-list__slider-slide" :style="`min-width: ${100 / cryptoActualSlides.length}%`"
                         v-for="(slide, s) in cryptoActualSlides" :key="`slide-${s}`">
                      <div class="rates-list__slider-item"
                           v-for="(item, i) in slide" :key="`slide-item-${i}`">
                        <div class="rates-list__item" :class="{'active': item.key === selCrypto}" @click.prevent="changeGraphAsset(item.key)">
                          <div class="rates-list__icon">
                            <img :src="`/img/icons/crypto/${item.key}.svg`" alt="" />
                          </div>
                          <div class="rates-list__info">
                            <div class="rates-list__info-row mb-2">
                              <p class="text ts-16 tw-500">{{ item.name }}</p>
                              <p class="text ts-16 tw-500">CZK {{ item.price }}</p>
                            </div>
                            <div class="rates-list__info-row">
                              <p class="text tc-gray ts-14 tw-400">{{ item.key.toUpperCase() }}</p>
                              <template v-if="cryptoActualChange[i + s * maxCryptoCount]">
                                <p class="text ts-14 tw-400"
                                   :class="[
                                     {'tc-up': cryptoActualChange[i + s * maxCryptoCount].change.grow},
                                     {'tc-down': !cryptoActualChange[i + s * maxCryptoCount].change.grow}]">
                                  CZK {{ cryptoActualChange[i + s * maxCryptoCount].change.amount }} ({{ cryptoActualChange[i + s * maxCryptoCount].change.percent }}%)
                                </p>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="rates-list__slider-control">
                    <button class="rates-list__slider-control-btn rates-list__slider-control-btn--prev" @click="slidePos === 0 ? 0 : slidePos--"></button>
                    <button class="rates-list__slider-control-btn rates-list__slider-control-btn--next" @click="slidePos === cryptoActualSlides.length - 1 ? cryptoActualSlides.length : slidePos++"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <p class="text tc-waikawa ts-20 tw-500 ta-center ta-md-left">Největší pohyby</p>
          </div>
        </div>
        <div class="row" v-if="cryptoChangesApi">
          <div class="col-12">
            <div class="rates-moves">
              <div class="rates-moves__item"
                   v-for="(item, key) in cryptoChangesApi" :key="`changes-${key}`">
                <div class="rates-moves__icon">
                  <img :src="`/img/icons/crypto/${key.toLowerCase()}.svg`" :alt="key" />
                </div>
                <div class="rates-moves__info mb-2">
                  <p class="text ts-16 tw-500">{{ key }}</p>
                  <template v-if="cryptoChangesSelected">
                    <p class="text tc-gray ts-14 tw-400">CZK {{ cryptoChangesSelected[key.toLowerCase()] }}</p>
                  </template>
                </div>
                <div class="rates-moves__change">
                  <p class="text ts-24 tw-400" :class="[{'tc-up': item > 0}, {'tc-down': item < 0}]">{{ item.toFixed(3) }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import RateGraph from '@/components/Widgets/Rate/RateGraph'
export default {
  components: {
    RateGraph
  },
  data: () => ({
    maxCryptoCount: 7,
    staticFormatArr: null,
    slidePos: 0,
    txtButtons: ['1 hod.', '1 den', '1 měs.', '3 měs.', '6 měs.', '1 rok']
  }),
  methods: {
    changeGraphRanges (v) {
      const sr = this.$store.state.selRange
      if (v !== sr) {
        this.$store.state.selRange = v
        if (this.cryptoHistoryApi[v].length === 0) {
          this.$store.commit('getCryptoHistory')
        } else {
          this.$store.state.isFetching = true
          setTimeout(() => { this.$store.state.isFetching = false }, 1000)
        }
      }
    },
    changeGraphAsset (v) {
      const sc = this.$store.state.selCrypto
      if (v !== sc) {
        this.$store.state.selCrypto = v
        if (!(v in this.cryptoBlockHeight)) this.$store.commit('getCryptoBlockHeight')
        this.$store.state.isFetching = true
        setTimeout(() => { this.$store.state.isFetching = false }, 1000)
        if (this.$store.state.ww < 992) window.scrollTo(0, 0)
      }
    }
  },
  computed: {
    rate () {
      return this.$store.state.cryptoUsdCzkRate
    },
    selRange () {
      return this.$store.state.selRange
    },
    selCrypto () {
      return this.$store.state.selCrypto
    },
    cryptoHistoryApi () {
      return this.$store.state.cryptoHistoryApi
    },
    cryptoActualApi () {
      return this.$store.state.cryptoActualApi
    },
    cryptoAssetData () {
      return this.$store.state.cryptoAssetsData
    },
    cryptoBlockHeight () {
      return this.$store.state.cryptoBlockHeight
    },
    cryptoActualChange () {
      const hist = this.cryptoHistoryApi
      const actu = this.cryptoActualApi
      const tmpArr = []
      if (hist && actu && this.cryptoHistoryApi[this.selRange][0]) {
        actu.forEach((item) => {
          const key = Object.keys(this.cryptoHistoryApi[this.selRange][0][item.key])[0]
          const price = +hist[this.selRange][0][item.key][key]
          if (price > 0) {
            const change = {
              grow: item.price > price,
              amount: Number((item.price > price ? item.price - price : price - item.price).toFixed(2)),
              percent: Number((100 / price * item.price - 100).toFixed(2))
              // percent: Number((item.price > price ? (item.price / price - 1) * 100 : (price / item.price - 1) * 100).toFixed(2))
            }
            tmpArr.push({ key: item.key, price, change })
          }
        })
      }
      console.log(tmpArr)
      return tmpArr
    },
    cryptoActualSlides () {
      const cryptoActualApi = this.cryptoActualApi
      const formatArr = []
      let tmpArr = []
      if (cryptoActualApi) {
        cryptoActualApi.forEach((item, i) => {
          tmpArr.push(item)
          if ((i + 1) % this.maxCryptoCount === 0 || (i + 1) === cryptoActualApi.length) {
            formatArr.push(tmpArr)
            tmpArr = []
          }
        })
      }
      return formatArr
    },
    cryptoActualSelected () {
      const cryptoActualApi = this.cryptoActualApi
      let res = null
      for (let i = 0; i < cryptoActualApi.length; i++) {
        if (cryptoActualApi[i].key === this.selCrypto) {
          res = cryptoActualApi[i]
          break
        }
      }
      return res
    },
    cryptoActualChangeSelected () {
      const cryptoActualChange = this.cryptoActualChange
      let res = null
      for (let i = 0; i < cryptoActualChange.length; i++) {
        if (cryptoActualChange[i].key === this.selCrypto) {
          res = cryptoActualChange[i]
          break
        }
      }
      return res
    },
    cryptoChangesApi () {
      return this.$store.state.cryptoChangesApi
    },
    cryptoChangesSelected () {
      const cryptoChangesApi = this.cryptoChangesApi
      const cryptoActualApi = this.cryptoActualApi
      const keyPool = []
      const res = {}
      for (const key in cryptoChangesApi) keyPool.push(key.toLowerCase())
      for (let i = 0; i < cryptoActualApi.length; i++) { if (keyPool.includes(cryptoActualApi[i].key)) res[cryptoActualApi[i].key] = cryptoActualApi[i].price }
      return res
    },
    sliderTranslate () {
      return `transform: translateX(-${(100 / 3) * this.slidePos}%)`
    }
  },
  beforeCreate () {
    /* const cryptoHistory = this.$store.state.cryptoHistory
    const cryptoTop = this.$store.state.cryptoTop
    if (!cryptoHistory['1h']) this.$store.commit('getCryptoHistory')
    if (!cryptoTop) this.$store.commit('getCryptoTop') */
  },
  metaInfo () {
    const meta = this.$t('meta.rates')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'keywords', content: meta.keywords },
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  mounted () {
    this.$store.commit('getCryptoBlockHeight')
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
